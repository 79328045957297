export function freeDrawCoords(
  allPoints: any,
  type: string
): { x: number; y: number }[] | string {
  const points: any = {
    firmness: [
      { x: allPoints.jaw[3].x, y: allPoints.jaw[3].y },
      { x: allPoints.jaw[2].x, y: allPoints.jaw[2].y },
      { x: allPoints.nose[6].x, y: allPoints.nose[6].y + 30 },
      { x: allPoints.jaw[14].x, y: allPoints.jaw[14].y },
      { x: allPoints.jaw[13].x, y: allPoints.jaw[13].y },
      { x: allPoints.jaw[12].x, y: allPoints.jaw[12].y },
      { x: allPoints.jaw[11].x, y: allPoints.jaw[11].y },
      { x: allPoints.jaw[10].x, y: allPoints.jaw[10].y },
      { x: allPoints.jaw[9].x, y: allPoints.jaw[9].y },
      { x: allPoints.jaw[8].x, y: allPoints.jaw[8].y },
      { x: allPoints.jaw[7].x, y: allPoints.jaw[7].y },
      { x: allPoints.jaw[6].x, y: allPoints.jaw[6].y },
      { x: allPoints.jaw[5].x, y: allPoints.jaw[5].y },
      { x: allPoints.jaw[4].x, y: allPoints.jaw[4].y },
      { x: allPoints.jaw[3].x, y: allPoints.jaw[3].y },
    ],
    texture: [
      { x: allPoints.jaw[1].x + 7, y: allPoints.jaw[1].y },
      { x: allPoints.jaw[0].x + 15, y: allPoints.jaw[0].y },
      { x: allPoints.leftEye[5].x, y: allPoints.leftEye[5].y + 50 },
      { x: allPoints.nose[0].x, y: allPoints.nose[0].y },
      { x: allPoints.rightEye[5].x, y: allPoints.rightEye[5].y + 50 },
      { x: allPoints.jaw[16].x, y: allPoints.jaw[16].y },
      { x: allPoints.jaw[15].x, y: allPoints.jaw[15].y },
      { x: allPoints.jaw[14].x, y: allPoints.jaw[14].y },
      { x: allPoints.jaw[13].x, y: allPoints.jaw[13].y },
      { x: allPoints.jaw[12].x, y: allPoints.jaw[12].y },
      { x: allPoints.nose[6].x, y: allPoints.nose[6].y },
      { x: allPoints.jaw[4].x, y: allPoints.jaw[4].y },
      { x: allPoints.jaw[3].x, y: allPoints.jaw[3].y },
      { x: allPoints.jaw[2].x, y: allPoints.jaw[2].y },
      { x: allPoints.jaw[1].x + 7, y: allPoints.jaw[1].y },
    ],
    redness: [
      { x: allPoints.jaw[1].x + 7, y: allPoints.jaw[1].y },
      { x: allPoints.jaw[0].x + 15, y: allPoints.jaw[0].y },
      { x: allPoints.leftEye[5].x, y: allPoints.leftEye[5].y + 50 },
      { x: allPoints.nose[0].x - 20, y: allPoints.nose[0].y },
      { x: allPoints.leftEyeBrow[4].x, y: allPoints.leftEyeBrow[4].y },
      { x: allPoints.leftEyeBrow[0].x - 10, y: allPoints.leftEyeBrow[1].y },
      { x: allPoints.leftEyeBrow[1].x, y: allPoints.leftEyeBrow[1].y - 100 },
      { x: allPoints.rightEyeBrow[3].x, y: allPoints.rightEyeBrow[3].y - 100 },
      {
        x: allPoints.rightEyeBrow[4].x + 20,
        y: allPoints.rightEyeBrow[4].y - 10,
      },
      { x: allPoints.rightEyeBrow[0].x, y: allPoints.rightEyeBrow[0].y },
      { x: allPoints.nose[0].x + 20, y: allPoints.nose[0].y },
      { x: allPoints.rightEye[3].x, y: allPoints.rightEye[3].y + 50 },
      { x: allPoints.jaw[16].x, y: allPoints.jaw[16].y },
      { x: allPoints.jaw[15].x, y: allPoints.jaw[15].y },
      { x: allPoints.jaw[14].x, y: allPoints.jaw[14].y },
      { x: allPoints.jaw[13].x, y: allPoints.jaw[13].y },
      { x: allPoints.jaw[12].x, y: allPoints.jaw[12].y },
      { x: allPoints.nose[6].x, y: allPoints.nose[6].y - 40 },
      { x: allPoints.jaw[4].x, y: allPoints.jaw[4].y },
      { x: allPoints.jaw[3].x, y: allPoints.jaw[3].y },
      { x: allPoints.jaw[2].x, y: allPoints.jaw[2].y },
      { x: allPoints.jaw[1].x + 7, y: allPoints.jaw[1].y },
    ],
    leftWrinkle: [
      { x: allPoints.leftEye[3].x, y: allPoints.leftEye[3].y + 7 },
      { x: allPoints.leftEye[4].x, y: allPoints.leftEye[4].y + 7 },
      { x: allPoints.leftEye[5].x, y: allPoints.leftEye[5].y + 10 },
      { x: allPoints.leftEye[0].x - 10, y: allPoints.leftEye[0].y + 5 },
      { x: allPoints.jaw[1].x + 40, y: allPoints.jaw[1].y },
      { x: allPoints.jaw[2].x + 40, y: allPoints.jaw[2].y - 15 },
      { x: allPoints.leftEye[3].x + 15, y: allPoints.leftEye[3].y + 7 },
      { x: allPoints.leftEye[3].x, y: allPoints.leftEye[3].y + 7 },
      { x: allPoints.leftEye[3].x, y: allPoints.leftEye[3].y + 7 },
    ],
    rightWrinkle: [
      { x: allPoints.rightEye[0].x, y: allPoints.rightEye[0].y + 7 },
      { x: allPoints.rightEye[5].x, y: allPoints.rightEye[5].y + 7 },
      { x: allPoints.rightEye[4].x, y: allPoints.rightEye[4].y + 10 },
      { x: allPoints.rightEye[3].x + 10, y: allPoints.rightEye[3].y + 5 },
      { x: allPoints.jaw[15].x - 40, y: allPoints.jaw[15].y },
      { x: allPoints.jaw[14].x - 40, y: allPoints.jaw[14].y - 15 },
      { x: allPoints.rightEye[0].x - 15, y: allPoints.rightEye[0].y + 7 },
      { x: allPoints.rightEye[0].x, y: allPoints.rightEye[0].y + 7 },
      { x: allPoints.rightEye[0].x, y: allPoints.rightEye[0].y + 7 },
    ],
  };

  return points[type] || "";
}
