export const calculateAverageBrightness = async (
  video: HTMLVideoElement
): Promise<number> => {
  return new Promise((resolve, reject) => {
    if (!video || video.readyState !== HTMLMediaElement.HAVE_ENOUGH_DATA) {
      reject(new Error("Video not ready or not available"));
    }

    const canvas = document.createElement("canvas");
    if (video.videoWidth > 0 && video.videoHeight > 0) {
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
    } else {
      console.error("Video dimensions are not available.");
      reject(new Error("Video dimensions are not available"));
    }

    const ctx = canvas.getContext("2d");

    if (ctx) {
      ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      const data = imageData.data;
      let colorSum = 0;

      if (data) {
        for (let x = 0, len = data.length; x < len; x += 4) {
          const r = data[x];
          const g = data[x + 1];
          const b = data[x + 2];

          const avg = Math.floor((r + g + b) / 3);
          colorSum += avg;
        }

        const averageBrightness = Math.floor(
          colorSum / (canvas.width * canvas.height)
        );

        resolve(averageBrightness);
      } else {
        reject(new Error("Image data not available"));
      }
    } else {
      reject(new Error("Canvas context not available"));
    }
  });
};
