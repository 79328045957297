import * as faceapi from "face-api.js";

export function isFaceAlignedAndStraight(
  faceLandmarks: faceapi.FaceLandmarks68
) {
  if (!faceLandmarks) return "bad";

  const leftEye = faceLandmarks.getLeftEye();
  const rightEye = faceLandmarks.getRightEye();
  const nose = faceLandmarks.getNose();

  if (!leftEye || !rightEye || !nose) return "bad";

  const leftEyeCenter = {
    x: (leftEye[0].x + leftEye[3].x) / 2,
    y: (leftEye[0].y + leftEye[3].y) / 2,
  };
  const rightEyeCenter = {
    x: (rightEye[0].x + rightEye[3].x) / 2,
    y: (rightEye[0].y + rightEye[3].y) / 2,
  };
  const noseTip = nose[3];

  const eyeCenter = {
    x: (leftEyeCenter.x + rightEyeCenter.x) / 2,
    y: (leftEyeCenter.y + rightEyeCenter.y) / 2,
  };

  const horizontalOffset = Math.abs(noseTip.x - eyeCenter.x);
  const verticalOffset = Math.abs(leftEyeCenter.y - rightEyeCenter.y);

  const maxHorizontalOffset = 20; // Adjust this value as needed
  const maxVerticalOffset = 10; // Adjust this value as needed

  if (
    horizontalOffset <= maxHorizontalOffset &&
    verticalOffset <= maxVerticalOffset
  ) {
    return "good";
  } else {
    return "bad";
  }
}
