import React, { useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Container,
  Collapse,
  IconButton,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

// Import images from src folder
const mainImage = require("./assets/landing.png");
const sec1 = require("./assets/sec1.jpg");
const sec2 = require("./assets/sec2.jpg");
const sec3 = require("./assets/sec3.jpg");
const sec4 = require("./assets/sec4.jpg");
const sections = [
  {
    title: "Discover Avant Skincare",
    text: "Explore the ultimate range of skincare products crafted for your unique needs. Your journey to radiant skin starts here.",
    image: sec1,
  },
  {
    title: "Take your selfie",
    text: "Use your phone camera to take a photo* of your face in bright, natural sunlight. Keep your eyes open and relax your face to a neutral expression. Ensure your skin is clean and free from makeup for the most accurate results.",
    image: sec2,
  },
  {
    title: "Review your results",
    text: "Receive your personalised skin health analysis and product recommendations.",
    image: sec3,
  },
  {
    title: "Discover your exclusive Avant routine",
    text: "Explore your Avant skincare routine for best results, including cleansers, serums, and moisturisers. Let’s illuminate your glow.",
    image: sec4,
  },
];

const PCView: React.FC = () => {
  const [isTermsOpen, setIsTermsOpen] = useState(false);

  const toggleTerms = () => {
    setIsTermsOpen((prev) => {
      const newState = !prev;
      if (newState) {
        setTimeout(() => {
          window.scrollTo({
            top: document.body.scrollHeight,
            behavior: "smooth",
          });
        }, 0);
      }
      return newState;
    });
  };

  return (
    <Container
      maxWidth="lg"
      sx={{ marginTop: "50px", fontFamily: "'neue_hans_kendrickregular'" }}
    >
      {/* Top Full-Width Image Section */}
      <Box sx={{ marginBottom: "50px" }}>
        <img
          src={mainImage}
          alt="Main Visual"
          style={{
            width: "100%",
            height: "auto",

            boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
          }}
        />
      </Box>
      <Box
        sx={{
          textAlign: "center",
          marginTop: "50px",
          marginBottom: "50px",
          fontFamily: "'neue_hans_kendrickregular'",
        }}
      >
        <Typography
          variant="h3"
          sx={{
            fontWeight: "bold",
            marginBottom: "20px",
            fontFamily: "'neue_hans_kendrickregular'",
          }}
        >
          How it Works
        </Typography>
        <Typography
          variant="h6"
          sx={{
            color: "#666",
            maxWidth: "700px",
            margin: "0 auto",
            lineHeight: 1.8,
            fontFamily: "'neue_hans_kendrickregular'",
          }}
        >
          Our advanced AI technology analyses wrinkles, firmness, expression
          lines, dark spots, oiliness, visible pores, radiance, redness, and
          texture. Based on these insights, we’ll provide you with a
          personalised skincare routine curated for your specific needs.
        </Typography>
      </Box>

      {/* Sections */}
      {sections.map((section, index) => (
        <Grid
          container
          spacing={4}
          key={index}
          sx={{
            fontFamily: "'neue_hans_kendrickregular'",
            marginBottom: "40px",
            flexDirection: index % 2 === 0 ? "row" : "row-reverse",
            alignItems: "center",
          }}
        >
          <Grid item xs={12} md={1}>
            <Typography
              variant="h1"
              sx={{
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              {index + 1}.
            </Typography>
          </Grid>
          <Grid item xs={12} md={5}>
            <Typography
              variant="h3"
              sx={{
                fontWeight: "bold",
                marginBottom: "20px",
                fontFamily: "'neue_hans_kendrickregular'",
              }}
            >
              {section.title}
            </Typography>
            <Typography
              variant="h6"
              sx={{
                color: "#666",
                fontFamily: "'neue_hans_kendrickregular'",
              }}
            >
              {section.text}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <img
              src={section.image}
              alt={`Section ${index + 1}`}
              style={{
                width: "100%",

                boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
              }}
            />
          </Grid>
        </Grid>
      ))}

      {/* Terms and Conditions */}
      <Box
        sx={{
          textAlign: "center",
          marginTop: "50px",
          marginBottom: "50px",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontWeight: "bold",
            display: "inline-block",
            verticalAlign: "middle",
            fontFamily: "'neue_hans_kendrickregular'",
          }}
        >
          Avant Virtual Skin Analysis Terms & Conditions
        </Typography>
        <IconButton
          onClick={toggleTerms}
          sx={{
            verticalAlign: "middle",
            marginLeft: "10px",
          }}
        >
          {isTermsOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
        <Collapse in={isTermsOpen} timeout="auto" unmountOnExit>
          <Box
            sx={{
              backgroundColor: "#f9f9f9",
              padding: "20px",

              marginTop: "20px",
              boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Typography
              variant="body1"
              sx={{
                color: "#666",
                lineHeight: 1.6,
                textAlign: "left",
                fontFamily: "'neue_hans_kendrickregular'",
              }}
            >
              Our virtual consultation offers personalised skincare
              recommendations. Please note that it is not a substitute for
              professional guidance. Avant is not responsible for service
              delivery issues caused by technical or connectivity problems.
              Users must be at least 16 years old to access this service. Your
              photo is not retained and is deleted immediately after the skin
              health analysis. Avant uses your input to generate a skin health
              analysis report and recommend personalised products. By utilising
              this service, you acknowledge that your interaction is with Avant
              and that your data is processed in compliance with Avant's Privacy
              Policy.
            </Typography>
          </Box>
        </Collapse>
      </Box>
    </Container>
  );
};

export default PCView;
